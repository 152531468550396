import { ChevronDownIcon, ChevronRightIcon } from "@heroicons/react/20/solid";
import {
  Dispatch,
  FC,
  Fragment,
  ReactNode,
  RefObject,
  SetStateAction,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  FormBuilderSortDroppable,
  FormBuilderSortableItem,
} from "@amenda-components/Settings/FormBuilder/FormBuilderDndComponents";
import { GripVerticalIcon, XIcon } from "lucide-react";
import {
  getAvailableOptions,
  getFlattenedOptions,
  getOptionAncestors,
  getOptionDescendants,
  getOptionDescendantsWithTree,
  getOptionsTree,
  getSearchFlatOptions,
} from "./common";
import { inputFieldTheme, tagTheme } from "@amenda-styles/theme";

import { IconButtonBase } from "@amenda-components/App";
import { MiniSearchField } from "@amenda-components/SearchComponents";
import { Option } from "@amenda-types";
import { PaddingRow } from "@amenda-components/PageBuilder/PaddingRow";
import { ReactSelectProps } from "./ReactSelect";
import { Transition } from "@headlessui/react";
import clsx from "clsx";
import { getPadding } from "@amenda-components/Shared/reactTableHelpers";
import { isEmpty } from "lodash";
import isNil from "lodash/isNil";
import { useVirtualizer } from "@tanstack/react-virtual";

interface Props extends ReactSelectProps {
  value?: string[];
  showTags?: boolean;
  dynamicMenuWidth?: boolean;
  size?: "sm" | "md" | "xs";
  onChange: (value: any) => void;
}

interface TagBreadCrumbProps {
  value: string;
  optionsTree: Record<string, any>;
  className?: string;
}

export const ReactTreeSelectBreadCrumb: FC<TagBreadCrumbProps> = ({
  value,
  optionsTree,
  className,
}) => {
  const optionAncestors = getOptionAncestors(value, optionsTree);

  return (
    <div className={clsx("flex items-center space-x-1", className)}>
      {optionAncestors.map((id) => (
        <Fragment key={id}>
          <span className="leading-none">{optionsTree[id].option.label}</span>
          <ChevronRightIcon className="h-3 min-h-3 w-3 min-w-3" />
        </Fragment>
      ))}
      {optionsTree[value] && (
        <span className="leading-none">{optionsTree[value].option.label}</span>
      )}
    </div>
  );
};

interface ReactTreeSelectMenuProps {
  options: Option[];
  className?: string;
  availableOptions: Option[];
  expandedOption: Record<string, boolean>;
  containerRef: RefObject<HTMLDivElement>;
  setAvailableOptions: Dispatch<SetStateAction<Option[]>>;
  setExpandedOption: Dispatch<SetStateAction<Record<string, boolean>>>;
  setSearchTerm: Dispatch<SetStateAction<string>>;
  onCloseMenu?: () => void;
  children: (props: {
    index: number;
    option: Option;
    hasChildren?: boolean;
  }) => ReactNode;
}

export const SearchComponentNestedMenu: FC<
  Omit<ReactTreeSelectMenuProps, "children"> & {
    optionVisibility: Record<string, boolean>;
    currOptionsCount: Record<string, number>;
    optionsCount: Record<string, number>;
    children: (props: {
      index: number;
      option: Option;
      isChecked: (updatedValues: string[]) => boolean;
    }) => ReactNode;
  }
> = ({
  containerRef,
  expandedOption,
  optionVisibility,
  currOptionsCount,
  optionsCount,
  availableOptions: options,
  className = "hover:bg-gray-900 hover:text-white",
  children,
  setAvailableOptions,
  setExpandedOption,
}) => {
  const availableOptions = useMemo(() => {
    return options.filter((o) => optionVisibility[o.value]);
  }, [options, optionVisibility]);

  const virtualizer = useVirtualizer({
    overscan: 10,
    count: availableOptions.length,
    estimateSize: () => 38,
    getScrollElement: () => containerRef.current,
  });
  const virtualRows = virtualizer.getVirtualItems();
  const totalSize = virtualizer.getTotalSize();
  const { paddingBottom, paddingTop } = getPadding(virtualRows, totalSize);

  const handleToggleOption = (index: number) => {
    const option = availableOptions[index];
    const depth = (option?.depth ?? 0) + 1;
    const isOpen = !Boolean(expandedOption[option.value]);
    const expandedOptions = {
      [option.value]: isOpen,
    };
    let newOptions: Option[] = [];

    if (isOpen) {
      const firstHalf = availableOptions.slice(0, index + 1);
      const secondHalf = availableOptions.slice(index + 1);

      newOptions = [
        ...firstHalf,
        ...(option?.children ?? []).map((child: Option) => ({
          ...child,
          depth,
        })),
        ...secondHalf,
      ];
    } else {
      const childIds = getOptionDescendants(option).map((o) => o.value);
      newOptions = availableOptions.filter((o) => !childIds.includes(o.value));
      childIds.forEach((id) => {
        expandedOptions[id] = false;
      });
    }

    setAvailableOptions(newOptions);
    setExpandedOption((prev) => ({
      ...prev,
      ...expandedOptions,
    }));
  };

  return (
    <div className="h-full w-full bg-white">
      <PaddingRow padding={paddingTop} />
      {virtualizer.getVirtualItems().map(({ index }) => {
        const option = availableOptions[index];
        const depth = option.depth ?? 0;
        const isOpen = Boolean(expandedOption[option.value]);
        const optionDescendants = getOptionDescendants(option)
          .map((o) => o.value)
          .filter((v) => optionsCount[v]);
        const hasChildren = optionDescendants.length > 0;

        return (
          <div
            key={option.value}
            className={clsx("flex w-full items-center text-sm", className)}
          >
            <div
              style={{
                paddingLeft: `${depth * 20}px`,
              }}
            />
            <div className="flex items-center space-x-2">
              {hasChildren ? (
                <button onClick={() => handleToggleOption(index)}>
                  <ChevronRightIcon
                    className={clsx("h-4 w-4", {
                      "rotate-90": isOpen,
                    })}
                  />
                </button>
              ) : (
                <div className="h-4 w-4" />
              )}
              {children({
                index,
                option,
                isChecked: (updatedValues: string[]) => {
                  let checked = updatedValues.includes(option.value);
                  if (
                    !checked &&
                    !isEmpty(updatedValues) &&
                    !isEmpty(optionDescendants)
                  ) {
                    checked = optionDescendants.every((v) =>
                      updatedValues.includes(v),
                    );
                  }
                  return checked;
                },
              })}
              {!hasChildren && (
                <span className="text-gray-500">
                  ({currOptionsCount[option.value] ?? 0})
                </span>
              )}
            </div>
          </div>
        );
      })}
      <PaddingRow padding={paddingBottom} />
    </div>
  );
};

export const FormBuilderNestedMenu: FC<
  Omit<ReactTreeSelectMenuProps, "containerRef">
> = ({
  availableOptions,
  expandedOption,
  className = "hover:bg-gray-900 hover:text-white",
  children,
  setAvailableOptions,
  setExpandedOption,
}) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const virtualizer = useVirtualizer({
    overscan: 10,
    count: availableOptions.length,
    estimateSize: () => 25,
    getScrollElement: () => containerRef.current,
  });
  const virtualRows = virtualizer.getVirtualItems();
  const totalSize = virtualizer.getTotalSize();
  const { paddingBottom, paddingTop } = getPadding(virtualRows, totalSize);

  const handleToggleOption = (index: number) => {
    const option = availableOptions[index];
    const depth = (option?.depth ?? 0) + 1;
    const isOpen = !Boolean(expandedOption[option.value]);
    const expandedOptions = {
      [option.value]: isOpen,
    };
    let newOptions: Option[] = [];

    if (isOpen) {
      const firstHalf = availableOptions.slice(0, index + 1);
      const secondHalf = availableOptions.slice(index + 1);

      newOptions = [
        ...firstHalf,
        ...(option?.children ?? []).map((child: Option) => ({
          ...child,
          depth,
        })),
        ...secondHalf,
      ];
    } else {
      const childIds = getOptionDescendants(option).map((o) => o.value);
      newOptions = availableOptions.filter((o) => !childIds.includes(o.value));
      childIds.forEach((id) => {
        expandedOptions[id] = false;
      });
    }

    setAvailableOptions(newOptions);
    setExpandedOption((prev) => ({
      ...prev,
      ...expandedOptions,
    }));
  };

  return (
    <div
      ref={containerRef}
      className="h-96 max-h-96 w-full overflow-y-auto overflow-x-hidden overscroll-contain text-sm"
    >
      <div className="h-full w-full bg-white">
        <PaddingRow padding={paddingTop} />
        {virtualizer.getVirtualItems().map(({ index }) => {
          const option = availableOptions[index];
          const depth = option.depth ?? 0;
          const isOpen = Boolean(expandedOption[option.value]);
          const hasChildren = option.children && option.children.length > 0;

          return (
            <FormBuilderSortDroppable
              key={option.value}
              getClassName={(isOver) => {
                return clsx({
                  "bg-gray-50": isOver,
                });
              }}
            >
              <FormBuilderSortableItem
                id={option.value}
                className="group w-full"
              >
                {() => (
                  <div
                    key={option.value}
                    className={clsx(
                      "flex w-full items-center text-sm",
                      className,
                    )}
                  >
                    <div
                      style={{
                        paddingLeft: `${depth * 20}px`,
                      }}
                    />
                    <IconButtonBase
                      size="xss"
                      className="cursor-grab text-gray-400"
                    >
                      <GripVerticalIcon className="h-4 w-4" />
                    </IconButtonBase>
                    {hasChildren && (
                      <button onClick={() => handleToggleOption(index)}>
                        <ChevronRightIcon
                          className={clsx("h-4 w-4", {
                            "rotate-90": isOpen,
                          })}
                        />
                      </button>
                    )}
                    {children({
                      index,
                      option,
                      hasChildren,
                    })}
                  </div>
                )}
              </FormBuilderSortableItem>
            </FormBuilderSortDroppable>
          );
        })}
        <PaddingRow padding={paddingBottom} />
      </div>
    </div>
  );
};

const ReactTreeSelectMenu: FC<ReactTreeSelectMenuProps> = ({
  containerRef,
  availableOptions,
  expandedOption,
  className = "hover:bg-gray-900 hover:text-white",
  children,
  setAvailableOptions,
  setExpandedOption,
}) => {
  const virtualizer = useVirtualizer({
    overscan: 10,
    count: availableOptions.length,
    estimateSize: () => 38,
    getScrollElement: () => containerRef.current,
  });
  const virtualRows = virtualizer.getVirtualItems();
  const totalSize = virtualizer.getTotalSize();
  const { paddingBottom, paddingTop } = getPadding(virtualRows, totalSize);

  const handleToggleOption = (index: number) => {
    const option = availableOptions[index];
    const depth = (option?.depth ?? 0) + 1;
    const isOpen = !Boolean(expandedOption[option.value]);
    const expandedOptions = {
      [option.value]: isOpen,
    };
    let newOptions: Option[] = [];

    if (isOpen) {
      const firstHalf = availableOptions.slice(0, index + 1);
      const secondHalf = availableOptions.slice(index + 1);

      newOptions = [
        ...firstHalf,
        ...(option?.children ?? []).map((child: Option) => ({
          ...child,
          depth,
        })),
        ...secondHalf,
      ];
    } else {
      const childIds = getOptionDescendants(option).map((o) => o.value);
      newOptions = availableOptions.filter((o) => !childIds.includes(o.value));
      childIds.forEach((id) => {
        expandedOptions[id] = false;
      });
    }

    setAvailableOptions(newOptions);
    setExpandedOption((prev) => ({
      ...prev,
      ...expandedOptions,
    }));
  };

  return (
    <div className="h-full w-full bg-white">
      <PaddingRow padding={paddingTop} />
      {virtualizer.getVirtualItems().map(({ index }) => {
        const option = availableOptions[index];
        const depth = option.depth ?? 0;
        const isOpen = Boolean(expandedOption[option.value]);
        const hasChildren = option.children && option.children.length > 0;

        return (
          <div
            key={option.value}
            className={clsx(
              "flex w-full items-center space-x-2 px-2 text-sm",
              className,
            )}
          >
            <div
              style={{
                paddingLeft: `${depth * 20}px`,
              }}
            />
            {hasChildren ? (
              <button onClick={() => handleToggleOption(index)}>
                <ChevronRightIcon
                  className={clsx("h-4 w-4", {
                    "rotate-90": isOpen,
                  })}
                />
              </button>
            ) : (
              <div className="h-4 w-4" />
            )}
            {children({
              index,
              option,
              hasChildren,
            })}
          </div>
        );
      })}
      <PaddingRow padding={paddingBottom} />
    </div>
  );
};

export const ReactTreeSelect: FC<Props> = ({
  options,
  value,
  placeholder,
  size = "md",
  showTags = true,
  canSelectParents = false,
  dynamicMenuWidth = false,
  onChange,
}) => {
  const optionsTree = useMemo(() => getOptionsTree(options), [options]);
  const { inputCss, multiSelectWrapperCss, searchInputCss } = inputFieldTheme();
  const [isOpen, setIsOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const wrapperRef = useRef<HTMLInputElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const [availableOptions, setAvailableOptions] = useState<Option[]>(options);
  const [expandedOption, setExpandedOption] = useState<Record<string, boolean>>(
    {},
  );

  const values = Array.isArray(value) ? value : !isNil(value) ? [value] : [];
  const wrapperWidth = wrapperRef?.current?.getBoundingClientRect().width;
  const allOptions = getFlattenedOptions(options);

  const handleToggle = () => {
    setIsOpen((prev) => !prev);
  };

  const handleInputWrapperClick = () => {
    if (showTags) {
      inputRef.current?.focus();
    }
    handleToggle();
  };

  const handleInputChangeWrapper = (value: string) => {
    setSearchTerm(value);
    if (value.length > 0) {
      const optionIds = getSearchFlatOptions(allOptions, value).map(
        (v) => v.value,
      );
      const { availableOptions, expandedOptions } = getAvailableOptions(
        optionsTree,
        optionIds,
      );

      setIsOpen(true);
      setAvailableOptions(availableOptions);
      setExpandedOption(expandedOptions);
    } else {
      setAvailableOptions(options);
      setExpandedOption({});
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    handleInputChangeWrapper(e.target.value);
  };

  const handleClear = () => {
    setSearchTerm("");
    setAvailableOptions(options);
    setExpandedOption({});
  };

  const handleClose = () => {
    handleClear();
    setIsOpen(false);
  };

  const handleSelectOption = (index: number) => {
    const option = availableOptions[index];
    const childIds = getOptionDescendants(option).map((o) => o.value);
    let updatedValues = values.map((v) => ({ value: v }));

    if (values.includes(option.value)) {
      updatedValues = updatedValues.filter(
        (v) => ![...childIds, v.value].includes(option.value),
      );
    } else {
      updatedValues = updatedValues.filter((v) => !childIds.includes(v.value));
      updatedValues = [{ value: option.value }, ...updatedValues];
    }

    onChange(updatedValues);
    handleClose();
  };

  const handleRemoveTag = (value: string) => {
    const item = optionsTree[value];

    // Ensure `item` is defined before accessing its properties
    if (!item) {
      console.error("Item not found in optionsTree for value:", value);
      return;
    }

    let updatedValues = values.map((v) => ({ value: v }));

    if (item.childrenIds && item.childrenIds.length > 0) {
      const childIds = getOptionDescendantsWithTree(value, optionsTree);

      updatedValues = updatedValues.filter((v) => v.value !== value);
      updatedValues = updatedValues.filter((v) => !childIds.includes(v.value));
    } else {
      updatedValues = updatedValues.filter((v) => v.value !== value);
    }

    onChange(updatedValues);
  };
  return (
    <div
      ref={wrapperRef}
      className="relative w-full"
      onMouseLeave={handleClose}
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
      }}
    >
      <div
        className={multiSelectWrapperCss({
          size,
        })}
      >
        <div
          className={inputCss({
            class: "flex w-full items-stretch justify-between p-0",
          })}
        >
          {showTags && values ? (
            <div
              className="flex w-full flex-wrap items-center overflow-x-hidden py-1 pl-2 pr-1"
              onClick={handleInputWrapperClick}
            >
              {values.map((value) => (
                <div
                  key={value}
                  className={tagTheme({
                    variant: "default",
                    class:
                      "m-[2px] h-max max-w-full justify-center bg-gray-200 p-0 text-xs",
                  })}
                >
                  <ReactTreeSelectBreadCrumb
                    value={value}
                    optionsTree={optionsTree}
                    className="max-w-full truncate pl-[6px] pr-1"
                  />
                  <button
                    className="h-full px-1 py-1.5 text-gray-600 hover:bg-red-200 hover:text-red-700"
                    onClick={(e) => {
                      e.stopPropagation();
                      handleRemoveTag(value);
                    }}
                  >
                    <XIcon className="h-[14px] w-[14px] stroke-[1.5]" />
                  </button>
                </div>
              ))}
              <input
                ref={inputRef}
                type="text"
                value={searchTerm}
                placeholder={placeholder}
                className="h-5 min-w-2 grow border-0 px-2 py-0 focus:outline-none focus:ring-0"
                style={{ width: `${searchTerm.length + 1}ch` }}
                onChange={handleInputChange}
              />
            </div>
          ) : (
            <div
              onClick={handleToggle}
              className="flex h-auto w-max grow cursor-pointer select-none items-center px-2 text-sm text-gray-500"
            >
              {placeholder}
            </div>
          )}
          <div
            className="flex cursor-pointer items-center pr-2"
            onClick={handleToggle}
          >
            <div className="h-[70%] border-l border-gray-300 pl-2" />
            <ChevronDownIcon
              className={clsx("h-5 w-5 select-none text-gray-500", {
                "rotate-180": isOpen,
              })}
            />
          </div>
        </div>
      </div>
      <div
        ref={containerRef}
        className={clsx(
          "z-50 max-h-60 overflow-y-auto overflow-x-hidden overscroll-contain shadow-lg",
          {
            fixed: dynamicMenuWidth,
            absolute: !dynamicMenuWidth,
          },
        )}
        style={{
          width: dynamicMenuWidth ? "maxContent" : wrapperWidth,
        }}
      >
        <Transition show={isOpen}>
          <div className="w-full pt-2 transition duration-100 ease-in data-[closed]:opacity-0">
            {!showTags && (
              <MiniSearchField
                className={searchInputCss({
                  size: "sm",
                  class:
                    "sticky top-0 mb-1 w-full border-b border-gray-100 bg-white",
                })}
                value={searchTerm}
                onClear={handleClear}
                onChange={handleInputChangeWrapper}
              />
            )}
            <ReactTreeSelectMenu
              options={options}
              containerRef={containerRef}
              expandedOption={expandedOption}
              availableOptions={availableOptions}
              setAvailableOptions={setAvailableOptions}
              setExpandedOption={setExpandedOption}
              setSearchTerm={setSearchTerm}
            >
              {({ index, option, hasChildren }) => (
                <button
                  disabled={hasChildren && !canSelectParents}
                  className="grow py-3 text-left leading-none"
                  onClick={() => handleSelectOption(index)}
                >
                  {option.label}
                </button>
              )}
            </ReactTreeSelectMenu>
          </div>
        </Transition>
      </div>
    </div>
  );
};
