import {
  ContactList,
  ContactListMobile,
  ContactOverview,
  ContactsFullScreenTable,
  ContactsLayout,
} from "@amenda-components/Contacts";
import { FC, useEffect, useState } from "react";
import {
  getContactRequestArgs,
  hasNoUserSearchAndGrouping,
} from "@amenda-components/Contacts/common";
import { isMobile, useRunOnMountOnly } from "@amenda-utils";
import { useNavigate, useParams } from "react-router-dom";
import {
  useUserSearchFiltersWithPath,
  useUsersStore,
} from "@amenda-domains/mutations";

import { AllowedContactType } from "@amenda-types";
import { ArrowLeft } from "lucide-react";
import { Button } from "@amenda-components/App";
import { ContactDirectoryProps } from "@amenda-components/Contacts/types";
import { MainContainer } from "@amenda-components/Shared";
import isEmpty from "lodash/isEmpty";
import { transformFilters } from "@amenda-components/Shared/common";
import { useTranslation } from "react-i18next";

interface Props extends ContactDirectoryProps {
  autoSelect: boolean;
  isCollection?: boolean;
  collectionResourceIds?: string[];
}

export const ContactDirectoryWrapper: FC<Props> = ({
  autoSelect,
  isCollection = false,
  collectionResourceIds = [],
  ...props
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isMounted, setIsMounted] = useState(false);
  const { userId } = useParams<{ userId: string }>();
  const { collectionId } = useParams<{ collectionId?: string }>();
  const users = useUsersStore((state) => state.users);
  const groupedUsers = useUsersStore((state) => state.groupedUsers);
  const selectedUser = useUsersStore((state) => state.selectedUser);
  const clearSelectedUsers = useUsersStore((state) => state.clearSelectedUsers);
  const setOpenContactListSlideOver = useUsersStore(
    (state) => state.setOpenContactListSlideOver,
  );
  const { searchFilters, userComponentFilters } =
    useUserSearchFiltersWithPath();
  const clearSelectedUser = useUsersStore((state) => state.clearSelectedUser);
  const colleaguesRefreshCount = useUsersStore(
    (state) => state.colleaguesRefreshCount,
  );
  const contactsRefreshCount = useUsersStore(
    (state) => state.contactsRefreshCount,
  );
  const userActivationState = useUsersStore(
    (state) => state.userActivationState,
  );

  const {
    rootRoute,
    pluralTitle,
    collectionRoute,
    getAllContacts,
    searchContacts,
    defaultContactType,
  } = props;
  const refreshCount =
    defaultContactType === AllowedContactType.office
      ? colleaguesRefreshCount
      : contactsRefreshCount;

  const handleOpenSlideOver = () => {
    if (userId) {
      const path = isCollection
        ? `${collectionRoute}/${collectionId}`
        : rootRoute;
      navigate(path);
    }
    setOpenContactListSlideOver(true);
  };

  useRunOnMountOnly(
    async () => {
      const key =
        defaultContactType === AllowedContactType.office
          ? "userDetails"
          : "contactDetails";
      const match = transformFilters(userComponentFilters, key);
      const args = getContactRequestArgs({
        autoSelect,
        isCollection,
        defaultContactType,
        collectionResourceIds,
        userActivationState,
        match,
        ...searchFilters,
      });

      clearSelectedUser();
      if (isCollection && isEmpty(collectionResourceIds)) {
        clearSelectedUsers();
      } else if (!hasNoUserSearchAndGrouping(searchFilters)) {
        await searchContacts(args);
      } else {
        await getAllContacts(args);
      }
    },
    JSON.stringify([
      isCollection,
      refreshCount,
      userActivationState,
      collectionResourceIds,
    ]),
  );

  useEffect(() => {
    if (!isMounted && !userId) {
      setIsMounted(true);
      if (isMobile(window)) {
        setOpenContactListSlideOver(true);
      }
    }
  }, [isMounted, userId, setOpenContactListSlideOver]);

  return (
    <ContactsLayout>
      <ContactsFullScreenTable
        users={users}
        isCollection={isCollection}
        {...props}
      />
      <ContactList
        users={users}
        isCollection={isCollection}
        selectedUser={selectedUser}
        groupedUsers={groupedUsers}
        {...props}
      />
      <ContactListMobile
        users={users}
        isCollection={isCollection}
        selectedUser={selectedUser}
        groupedUsers={groupedUsers}
        {...props}
      />
      <MainContainer className="h-full w-full">
        <div className="h-full w-full overflow-y-auto overscroll-y-contain pb-10 pt-8 md:pt-0">
          <div className="absolute left-0 top-0 z-20 flex w-full items-end bg-white px-2 pt-4 lg:hidden">
            <Button onClick={handleOpenSlideOver} variant="default">
              <span className="mr-2 flex flex-row items-center">
                <ArrowLeft className="w-4" /> {t(`All ${pluralTitle}`)}
              </span>
            </Button>
          </div>
          <ContactOverview isCollection={isCollection} {...props} />
        </div>
      </MainContainer>
    </ContactsLayout>
  );
};
