import {
  AppRoutes,
  AvailableImageVariants,
  RoundedVariants,
} from "@amenda-types";
import { AttachmentActionBar, GalleryTabs } from "@amenda-components/Gallery";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/24/solid";
import {
  DetectedFaces,
  MainContainer,
  SidebarContainer,
} from "@amenda-components/Shared";
import { FC, KeyboardEvent } from "react";
import { IconButton, Image } from "@amenda-components/App";
import { useNavigate, useParams } from "react-router-dom";

import { MaxWidthLayout } from "@amenda-components/Shared";
import { ScanFace } from "lucide-react";
import { TitleAndSubtitle } from "@amenda-components/PdfBuilder";
import { formatFileSizeResult } from "@amenda-components/FileUploads/common";
import { useAttachmentStore } from "@amenda-domains/mutations";
import { useFaceAPI } from "@amenda-context";

interface Props {
  loading: boolean;
}

export const ViewOrEditAttachment: FC<Props> = ({ loading }) => {
  const navigate = useNavigate();
  const { collectionId } = useParams<{ collectionId?: string }>();
  const selectedAttachment = useAttachmentStore(
    (state) => state.selectedAttachment,
  );
  const attachments = useAttachmentStore((state) => state.attachments);

  const {
    processing,
    detectedFaces,
    isModelLoaded,
    cleanUpFaceDetection,
    processImageForFaceRecognition,
  } = useFaceAPI();

  const currentAttachmentIndex = attachments.findIndex(
    (attachment) => attachment.id === selectedAttachment?.id,
  );

  const handlePrevious = () => {
    const attachment = attachments[currentAttachmentIndex - 1];
    const path = collectionId
      ? `${AppRoutes.AttachmentsCollection}/${attachment?.id}`
      : `${AppRoutes.Attachments}/${attachment?.id}`;

    cleanUpFaceDetection();
    navigate(path);
  };
  const handleNext = () => {
    const attachment = attachments[currentAttachmentIndex + 1];
    const path = collectionId
      ? `${AppRoutes.AttachmentsCollection}/${attachment?.id}`
      : `${AppRoutes.Attachments}/${attachment?.id}`;

    cleanUpFaceDetection();
    navigate(path);
  };
  const handleKeyDown = (e: KeyboardEvent<HTMLDivElement>) => {
    const targetKey = e.key;
    if (targetKey === "ArrowLeft" && currentAttachmentIndex > 0) {
      handlePrevious();
    } else if (
      targetKey === "ArrowRight" &&
      currentAttachmentIndex + 1 < attachments.length
    ) {
      handleNext();
    }
  };

  return (
    <MaxWidthLayout
      wrapperClassName="h-screen"
      className="flex h-full xxl:w-[1200px]"
    >
      <MainContainer tabIndex={-1} onKeyDown={handleKeyDown}>
        <div>
          <div className="pb-2 lg:hidden">
            <AttachmentActionBar />
          </div>
          <div className="flex">
            <h3 className="mb-2 flex-1 pt-1 text-xl leading-6 text-gray-900">
              {selectedAttachment?.originalFilename}
            </h3>
            <IconButton
              className="mr-auto"
              Icon={ScanFace}
              label="Detected faces"
              variant="base"
              onClick={() =>
                processImageForFaceRecognition(
                  selectedAttachment?.originalFilename,
                )
              }
              disabled={!isModelLoaded}
            />
          </div>
        </div>
        <Image
          id={selectedAttachment?.originalFilename}
          url={selectedAttachment?.url}
          isLoading={loading}
          showLoader={true}
          size="pb-6/12"
          magicSize="fullscreen"
          lazyLoad={false}
          variant={AvailableImageVariants.scaleDown}
          rounded={RoundedVariants.none}
          enableHighlighting={false}
        >
          {(imageDimensions) => (
            <DetectedFaces
              isLoading={processing}
              detectedFaces={detectedFaces}
              imageDimensions={imageDimensions}
            />
          )}
        </Image>
        <div className="mt-4 flex items-center justify-center py-2 lg:px-8">
          <IconButton
            className="mr-auto"
            Icon={ChevronLeftIcon}
            label="Previous"
            variant="inverted"
            onClick={handlePrevious}
            disabled={currentAttachmentIndex <= 0}
          />
          <TitleAndSubtitle title="Dimensions">
            {selectedAttachment?.metadata?.height &&
            selectedAttachment?.metadata?.width
              ? `${selectedAttachment?.metadata?.height}px mal ${selectedAttachment?.metadata?.width}px`
              : "-"}
          </TitleAndSubtitle>
          <TitleAndSubtitle title="Size">
            {`${formatFileSizeResult(selectedAttachment?.metadata?.size)}`}
          </TitleAndSubtitle>
          <TitleAndSubtitle title="File">
            {selectedAttachment?.metadata?.type}
          </TitleAndSubtitle>
          <IconButton
            className="ml-auto"
            Icon={ChevronRightIcon}
            label="Next"
            variant="inverted"
            onClick={handleNext}
            disabled={currentAttachmentIndex + 1 >= attachments.length}
          />
        </div>
        <div className="lg:hidden">
          <GalleryTabs loading={loading} />
        </div>
      </MainContainer>
      <SidebarContainer>
        <GalleryTabs loading={loading} />
      </SidebarContainer>
    </MaxWidthLayout>
  );
};
