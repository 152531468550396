import {
  ActionBar,
  SaveIndicator,
  Spinner,
  Tooltip,
} from "@amenda-components/App";
import {
  AppRoutes,
  AvailableNotificationTypes,
  GeneralPermissionTypes,
} from "@amenda-types";
import {
  ArrowDownTrayIcon,
  FolderIcon,
  LinkIcon,
  PencilIcon,
  TrashIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import { FC, useState } from "react";
import {
  GeneralPermissionKeys,
  getFromGeneralPermissions,
} from "@amenda-components/Settings/common";
import {
  PermissionSharePath,
  ResourceSharingPermissionTypes,
} from "@amenda-components/Shared/common";
import { copyToClickBoard, downloadFromUrl } from "@amenda-utils";
import {
  useAppStore,
  useAttachmentStore,
  useSettingsStore,
  useUpdateAttachment,
} from "@amenda-domains/mutations";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import { DeleteModal } from "@amenda-components/Shared";
import { GeneralAccessTypes } from "@amenda-constants";
import { Share2Icon } from "lucide-react";
import { ShareModal } from "@amenda-components/Shared/ShareModal";
import clsx from "clsx";
import { useFaceAPI } from "@amenda-context";

export const AttachmentActionBar: FC = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { collectionId } = useParams<{ collectionId?: string }>();
  const [openModal, setOpenModal] = useState(false);
  const [openShareModal, setOpenShareModal] = useState(false);
  const [hasCopiedLink, setHasCopiedLink] = useState(false);
  const selectedAttachment = useAttachmentStore(
    (state) => state.selectedAttachment,
  );
  const isSavingAttachment = useAttachmentStore(
    (state) => state.isSavingAttachment,
  );
  const showNotification = useAppStore((state) => state.showNotification);
  const toggleMoveAttachmentsModal = useAttachmentStore(
    (state) => state.toggleMoveAttachmentsModal,
  );
  const toggleSelectedAttachment = useAttachmentStore(
    (state) => state.toggleSelectedAttachment,
  );
  const permissions = useSettingsStore(
    (state) => state.currentUserSystemRole?.permissions || {},
  );
  const { updateAttachment, loading } = useUpdateAttachment();
  const { cleanUpFaceDetection } = useFaceAPI();
  const [isDownloading, setIsDownloading] = useState(false);

  const isEditing = pathname.endsWith(`/${selectedAttachment.id}/bearbeiten`);

  const mediaGeneralPermissions = getFromGeneralPermissions(
    permissions,
    GeneralPermissionKeys.Media,
  );

  const closeAttachment = () => {
    let path = isEditing
      ? `${AppRoutes.Attachments}/${selectedAttachment.id}`
      : AppRoutes.Attachments;
    if (collectionId) {
      path = isEditing
        ? `${AppRoutes.AttachmentsCollection}/${selectedAttachment.id}`
        : AppRoutes.AttachmentsCollection;
    }

    cleanUpFaceDetection();
    navigate(path);
  };

  const handleEdit = () => {
    const path = collectionId
      ? `${AppRoutes.AttachmentsCollection}/${selectedAttachment.id}/bearbeiten`
      : `${AppRoutes.Attachments}/${selectedAttachment.id}/bearbeiten`;

    navigate(path);
  };

  const handleCopyToClickboard = () => {
    copyToClickBoard({
      text: selectedAttachment?.url || "",
      onSuccess: () => {
        showNotification(
          AvailableNotificationTypes.Success,
          "Link copied to clipboard",
        );
        setHasCopiedLink(true);
      },
    });
  };

  const download = async () => {
    try {
      if (selectedAttachment?.url) {
        const url = selectedAttachment.url + `/original`;
        const filename =
          selectedAttachment.originalFilename ?? selectedAttachment.filename;

        setIsDownloading(true);
        await downloadFromUrl(url, filename);
      }
    } catch (err) {
      showNotification(
        AvailableNotificationTypes.Error,
        "File download failed",
      );
    } finally {
      setIsDownloading(false);
    }
  };

  const deleteAttachment = async () => {
    await updateAttachment({
      input: {
        _id: selectedAttachment.id,
        isDeleted: true,
      },
    });
    setOpenModal(false);
    closeAttachment();
  };

  const handleCloseShare = () => setOpenShareModal(false);

  const handleMoveToFolder = () => {
    toggleSelectedAttachment(selectedAttachment.id);
    toggleMoveAttachmentsModal(true);
  };

  const handleShare = async (type: GeneralAccessTypes, users: any[]) => {
    const share = {
      type,
      users: users.map((u) => ({
        id: u._id,
        role: u[PermissionSharePath] || ResourceSharingPermissionTypes.View,
      })),
    };

    await updateAttachment({
      input: {
        share,
        _id: selectedAttachment.id,
      },
    });
    setOpenShareModal(false);
  };

  return (
    <>
      <DeleteModal
        title="Delete Attachment"
        description="Are you sure you want to delete this attachment?"
        loading={loading}
        openModal={openModal}
        handleDelete={deleteAttachment}
        handleCloseModal={() => setOpenModal(false)}
      />
      <ShareModal
        title="Share attachment with"
        loading={loading}
        openModal={openShareModal}
        ownerId={selectedAttachment?.ownerId}
        shareType={selectedAttachment?.share?.type}
        shareWith={selectedAttachment?.share?.users || []}
        onSubmit={handleShare}
        handleClose={handleCloseShare}
      />
      <ActionBar>
        {({ IconButton }) => (
          <>
            {isEditing ? (
              <SaveIndicator isSubmitting={isSavingAttachment} />
            ) : (
              <>
                {mediaGeneralPermissions[GeneralPermissionTypes.Share] && (
                  <Tooltip id="copyLink" message="Copy Link">
                    <IconButton
                      Icon={LinkIcon}
                      label="Copy Link"
                      onClick={handleCopyToClickboard}
                      className={clsx({
                        "animate-pulse-alert": hasCopiedLink,
                      })}
                    />
                  </Tooltip>
                )}
                {mediaGeneralPermissions[GeneralPermissionTypes.Share] && (
                  <Tooltip id="share" message="Share">
                    <IconButton
                      Icon={Share2Icon}
                      label="Share"
                      variant="default"
                      onClick={() => setOpenShareModal(true)}
                    />
                  </Tooltip>
                )}
                {mediaGeneralPermissions[GeneralPermissionTypes.Download] && (
                  <Tooltip id="downloadImage" message="Download Image">
                    {isDownloading ? (
                      <Spinner spinnerSize="xs" variant="secondary" />
                    ) : (
                      <IconButton
                        Icon={ArrowDownTrayIcon}
                        label="Download Image"
                        onClick={download}
                      />
                    )}
                  </Tooltip>
                )}
                {mediaGeneralPermissions[GeneralPermissionTypes.Move] && (
                  <Tooltip id="moveToFolder" message="Move to Folder">
                    <IconButton
                      Icon={FolderIcon}
                      label="Move to Folder"
                      onClick={handleMoveToFolder}
                      disabled={isEditing}
                    />
                  </Tooltip>
                )}
                {mediaGeneralPermissions[GeneralPermissionTypes.Delete] && (
                  <Tooltip id="deleteImage" message="Delete Image">
                    <IconButton
                      Icon={TrashIcon}
                      label="Delete Image"
                      onClick={() => setOpenModal(true)}
                      disabled={loading}
                    />
                  </Tooltip>
                )}
                {mediaGeneralPermissions[GeneralPermissionTypes.Edit] && (
                  <Tooltip id="toggleEdit" message="Edit Attachment">
                    <IconButton
                      Icon={PencilIcon}
                      label="Edit Attachment"
                      onClick={handleEdit}
                    />
                  </Tooltip>
                )}
              </>
            )}
            <IconButton
              Icon={XMarkIcon}
              label="Close Modal"
              onClick={closeAttachment}
            />
          </>
        )}
      </ActionBar>
    </>
  );
};
