import { AllowedAttachmentType, AppRoutes } from "@amenda-types";
import { FC, useEffect } from "react";
import {
  useAttachmentStore,
  useSidebarFiltersWithPath,
} from "@amenda-domains/mutations";

import { MediaGallery } from "@amenda-components/Gallery";
import isEmpty from "lodash/isEmpty";
import { transformFilters } from "@amenda-components/Shared/common";
import { useSearchAttachments } from "@amenda-domains/queries";

interface Props {
  projectId: string;
}

export const ReadOnlyProjectMedia: FC<Props> = ({ projectId }) => {
  const { searchAttachments } = useSearchAttachments();
  const attachments = useAttachmentStore((state) => state.attachments);
  const setIsSearching = useAttachmentStore((state) => state.setIsSearching);
  const setAttachments = useAttachmentStore((state) => state.setAttachments);
  const { sidebarFilters, searchTerm } = useSidebarFiltersWithPath();

  const handleSearchAttachments = async (searchTerm: string) => {
    if (!isEmpty(searchTerm) && projectId) {
      setIsSearching(true);
      setAttachments({
        attachments: [],
      });
      await searchAttachments({
        searchTerm,
        filters: {
          ...transformFilters(sidebarFilters),
          "formValues.project": projectId,
          type: AllowedAttachmentType.image,
        },
      });
      setIsSearching(false);
    }
  };

  useEffect(() => {
    const searchAttachmentAsync = async () => {
      if (projectId && isEmpty(searchTerm)) {
        setIsSearching(true);
        setAttachments({
          attachments: [],
        });
        await searchAttachments({
          filters: {
            ...transformFilters(sidebarFilters),
            "formValues.project": projectId,
            type: AllowedAttachmentType.image,
          },
        });
        setIsSearching(false);
      }
    };

    searchAttachmentAsync();
  }, [
    projectId,
    sidebarFilters,
    searchTerm,
    setIsSearching,
    setAttachments,
    searchAttachments,
  ]);

  return (
    <MediaGallery
      canUpload={false}
      label="Media"
      rootRoute={AppRoutes.Attachments}
      attachments={attachments}
      searchAttachments={handleSearchAttachments}
    />
  );
};
