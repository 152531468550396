import { FC, ReactNode } from "react";

import { Layout } from "@amenda-components/App";

interface Props {
  children: ReactNode;
  hideSidebar?: boolean;
}

export const PrivateRouteWrapper: FC<Props> = ({ children, hideSidebar }) => {
  return <Layout hideSidebar={hideSidebar}>{children}</Layout>;
};
