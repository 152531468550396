import {
  AddressSearch,
  CheckBox,
  DatePickerRange,
  InputRangePicker,
  SearchComponentNestedMenu,
  SingleCheckbox,
  TextField,
} from "@amenda-components/FormComponents";
import {
  ComponentValidationType,
  LogicalOperators,
  Option,
} from "@amenda-types";
import { FC, useEffect, useMemo, useState } from "react";
import { FormComponentTypes, SearchCollections } from "@amenda-constants";
import { IconButton, Skeleton } from "@amenda-components/App";
import {
  getAvailableOptions,
  getFlattenedOptions,
  getOptionAncestors,
  getOptionDescendantsWithTree,
  getOptionsTree,
  getSearchFlatOptions,
} from "@amenda-components/FormComponents/common";
import { useGetKeywords, useGetKeywordsCount } from "@amenda-domains/queries";

import Fuse from "fuse.js";
import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";
import { MapPinIcon } from "@heroicons/react/24/solid";
import { MiniSearchField } from "@amenda-components/SearchComponents";
import { RegionalSelect } from "./RegionalSelect";
import { SearchAndSelectProjects } from "@amenda-components/Projects";
import { SidebarSearchContactByType } from "@amenda-components/Contacts";
import { inputFieldTheme } from "@amenda-styles/theme";
import { isEmpty } from "lodash";
import { useAppStore } from "@amenda-domains/mutations";
import { useTranslation } from "react-i18next";

interface Props {
  component: any;
  values?: any;
  operation?: LogicalOperators;
  formValues?: any[];
  isSearching: boolean;
  hasSearchFilters: boolean;
  searchCollection: SearchCollections;
  onChange: (values: any) => void;
}

const getCount = (componentId: string, formValues: any[]) => {
  const keywordCount: Record<string, number> = {};
  const stack = [...formValues]
    .filter(Boolean)
    .map((formValue) => formValue[componentId])
    .filter((v) => !isEmpty(v));

  while (stack.length > 0) {
    const keywords = stack.pop();

    if (Array.isArray(keywords)) {
      keywords.forEach((keyword: string) => {
        if (keywordCount[keyword]) {
          keywordCount[keyword] += 1;
        } else {
          keywordCount[keyword] = 1;
        }
      });
    } else {
      if (keywordCount[keywords]) {
        keywordCount[keywords] += 1;
      } else {
        keywordCount[keywords] = 1;
      }
    }
  }

  return keywordCount;
};

const getNestedOptionVisibility = (
  optionsTree: Record<string, any>,
  optionsCount: Record<string, number>,
) => {
  const optionsVisibility: Record<string, boolean> = {};
  Object.keys(optionsCount)
    .filter((key) => Number.isFinite(optionsCount[key]))
    .forEach((key) => {
      const ancestors = getOptionAncestors(key, optionsTree);

      optionsVisibility[key] = true;

      ancestors.forEach((ancestor) => {
        optionsVisibility[ancestor] = true;
      });
    });

  return optionsVisibility;
};

export const KeywordCheckboxWrapper: FC<Props> = ({
  component,
  values,
  operation,
  isSearching,
  hasSearchFilters,
  searchCollection,
  formValues = [],
  onChange,
}) => {
  const { t } = useTranslation();
  const keywords = useAppStore(
    (state) => state.sidebarKeywords[component?.id] ?? [],
  );
  const setSidebarKeywords = useAppStore((state) => state.setSidebarKeywords);
  const [availableKeywords, setAvailableKeywords] = useState<any[]>([]);
  const [showSearch, setShowSearch] = useState<boolean>();
  const [searchTerm, setSearchTerm] = useState<string>("");
  const { getKeywords, loading } = useGetKeywords();
  const { searchInputCss } = inputFieldTheme();
  const { loading: isLoadingCount, getKeywordsCount } = useGetKeywordsCount();
  const [keywordsCount, setKeywordsCount] = useState<any>({});
  const formValuesCount = useMemo(
    () => getCount(component?.id, formValues),
    [component?.id, formValues],
  );

  const currKeywordsCount =
    hasSearchFilters && operation !== LogicalOperators.OR
      ? formValuesCount
      : keywordsCount;
  const keywordIds = Object.keys(keywordsCount);
  const options = availableKeywords
    .filter((k) => k?.id && keywordIds.includes(k.id))
    .map((k) => ({
      value: k.id,
      label: k.name,
      count: currKeywordsCount[k.id] ?? 0,
    }));

  const handleClick = () => {
    setSearchTerm("");
    setShowSearch(false);
    setAvailableKeywords(keywords);
  };

  const handleSearch = (searchTerm: string) => {
    let availableKeywords = [...keywords];
    if (searchTerm) {
      const fuse = new Fuse(keywords, {
        includeScore: true,
        keys: ["name"],
      });
      const results = fuse.search(searchTerm);
      availableKeywords = results.map((res) => res.item);
    }

    setSearchTerm(searchTerm);
    setAvailableKeywords(availableKeywords);
  };

  const toggleSelectAll = () => {
    if (Boolean(values?.length === options.length)) {
      onChange([]);
    } else {
      onChange(options.map((o) => o.value));
    }
  };

  useEffect(() => {
    if (component?.id) {
      getKeywordsCount({
        collections: [
          {
            collection: searchCollection,
            keywordPath: `formValues.${component.id}`,
          },
        ],
        callback: (data) => {
          const keywordsCount = data?.[0]?.keywordCount ?? {};
          setKeywordsCount(keywordsCount);
        },
      });
    }
  }, [component?.id, searchCollection, getKeywordsCount]);

  useEffect(() => {
    const getKeywordsAsync = async () => {
      if (component?.id) {
        const keywords = await getKeywords({
          componentIds: [component.id],
          setKeywords: (keywords) => setSidebarKeywords(component.id, keywords),
        });
        setAvailableKeywords(keywords);
      }
    };

    getKeywordsAsync();
  }, [component?.id, getKeywords, setSidebarKeywords]);

  useEffect(() => {
    return () => {
      setSearchTerm("");
      setAvailableKeywords([]);
    };
  }, []);

  if (loading || isLoadingCount || isSearching) {
    return (
      <div className="mb-3 mt-1">
        <Skeleton height={5} />
      </div>
    );
  } else if (isEmpty(options) && !searchTerm) {
    return (
      <div className="mt-1 pb-2 text-sm text-gray-400">
        {t("No keywords found")}
      </div>
    );
  }
  return (
    <div className="grid">
      <div className="flex items-center">
        {showSearch ? (
          <div className="w-full pb-3 pl-0">
            <MiniSearchField
              className={searchInputCss({
                size: "sm",
                class: "w-full border border-gray-300",
              })}
              placeholder="Liste durchsuchen ..."
              value={searchTerm}
              onChange={handleSearch}
              onClear={handleClick}
            />
          </div>
        ) : (
          <div className="flex h-12 items-center">
            <IconButton
              className="ml-[7px]"
              iconSize={1}
              Icon={MagnifyingGlassIcon}
              label="Open Search"
              onClick={() => setShowSearch(true)}
            />
          </div>
        )}
      </div>
      {options.length > 1 && (
        <div className="pb-0.5">
          <SingleCheckbox
            id="select_all"
            label="Select All"
            checked={Boolean(values?.length === options.length)}
            onChange={toggleSelectAll}
          />
        </div>
      )}
      <CheckBox
        className="mb-3"
        id={component?.id}
        options={options}
        onChange={onChange}
        selectedOptions={values || []}
      />
    </div>
  );
};

const NestedFilterCheckBox: FC<Props> = ({
  component,
  values,
  operation,
  isSearching,
  hasSearchFilters,
  searchCollection,
  formValues = [],
  onChange,
}) => {
  const { t } = useTranslation();
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [showSearch, setShowSearch] = useState<boolean>();
  const [expandedOption, setExpandedOption] = useState<any>({});
  const { loading: isLoadingCount, getKeywordsCount } = useGetKeywordsCount();
  const [optionsCount, setOptionsCount] = useState<any>({});
  const formValuesCount = useMemo(
    () => getCount(component?.id, formValues),
    [component?.id, formValues],
  );

  const options = useMemo(
    () => component?.properties?.options ?? [],
    [component?.properties?.options],
  );
  const [availableOptions, setAvailableOptions] = useState<any[]>(options);
  const { searchInputCss } = inputFieldTheme();
  const optionsTree = getOptionsTree(options);
  const allOptions = getFlattenedOptions(options);
  const updatedValues: string[] = values ?? [];
  const currOptionsCount =
    hasSearchFilters && operation !== LogicalOperators.OR
      ? formValuesCount
      : optionsCount;
  const optionVisibility = useMemo(
    () => getNestedOptionVisibility(optionsTree, optionsCount),
    [optionsTree, optionsCount],
  );
  const allAvailableOptions = getFlattenedOptions(options);

  const handleClick = () => {
    setSearchTerm("");
    setShowSearch(false);
    setAvailableOptions(options);
  };

  const handleCheckboxChange = (option: Option) => (checked?: boolean) => {
    let value: string[] = [];
    const childIds = getOptionDescendantsWithTree(option.value, optionsTree);
    const parentId = optionsTree[option.value]?.parentId;

    if (checked) {
      const valuesToAdd = [...updatedValues, option.value, ...childIds];

      if (parentId) {
        const descendants = getOptionDescendantsWithTree(parentId, optionsTree);
        if (descendants.every((v) => valuesToAdd.includes(v))) {
          valuesToAdd.push(parentId);
        }
      }

      value = valuesToAdd;
    } else {
      const valuesToRemove = [option.value, ...childIds];
      if (parentId) {
        valuesToRemove.push(parentId);
      }
      value = updatedValues.filter((v) => !valuesToRemove.includes(v));
    }
    onChange(value);
  };

  const handleSearch = (searchTerm: string) => {
    setSearchTerm(searchTerm);
    if (searchTerm.length > 0) {
      const optionIds = getSearchFlatOptions(allOptions, searchTerm).map(
        (v) => v.value,
      );
      const { availableOptions, expandedOptions } = getAvailableOptions(
        optionsTree,
        optionIds,
      );

      setAvailableOptions(availableOptions);
      setExpandedOption(expandedOptions);
    } else {
      setAvailableOptions(options);
      setExpandedOption({});
    }
  };

  const toggleSelectAll = () => {
    if (Boolean(values?.length === allAvailableOptions.length)) {
      onChange([]);
    } else {
      onChange(allAvailableOptions.map((o) => o.value));
    }
  };

  useEffect(() => {
    if (component?.id) {
      getKeywordsCount({
        collections: [
          {
            collection: searchCollection,
            keywordPath: `formValues.${component.id}`,
          },
        ],
        callback: (data) => {
          const keywordsCount = data?.[0]?.keywordCount ?? {};
          setOptionsCount(keywordsCount);
        },
      });
    }
  }, [component?.id, searchCollection, getKeywordsCount]);

  if (isLoadingCount || isSearching) {
    return (
      <div className="mb-3 mt-1">
        <Skeleton height={5} />
      </div>
    );
  } else if (isEmpty(availableOptions) && !searchTerm) {
    return (
      <div className="mt-1 pb-2 text-sm text-gray-400">
        {t("No filters found")}
      </div>
    );
  }
  return (
    <div
      className="grid"
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
      }}
    >
      <div className="flex items-center">
        {showSearch ? (
          <div className="w-full pb-3 pl-0">
            <MiniSearchField
              className={searchInputCss({
                size: "sm",
                class: "w-full border border-gray-300",
              })}
              placeholder="Liste durchsuchen ..."
              value={searchTerm}
              onChange={handleSearch}
              onClear={handleClick}
            />
          </div>
        ) : (
          <div className="flex h-12 items-center">
            <IconButton
              className="ml-[7px]"
              iconSize={1}
              Icon={MagnifyingGlassIcon}
              label="Open Search"
              onClick={() => setShowSearch(true)}
            />
          </div>
        )}
      </div>
      {availableOptions.length > 1 && (
        <div className="flex items-center space-x-2 pb-0.5">
          <div className="h-4 w-4" />
          <SingleCheckbox
            id="select_all"
            label="Select All"
            checked={Boolean(values?.length === allAvailableOptions.length)}
            onChange={toggleSelectAll}
          />
        </div>
      )}
      <SearchComponentNestedMenu
        options={options}
        expandedOption={expandedOption}
        availableOptions={availableOptions}
        className="pb-0.5"
        optionVisibility={optionVisibility}
        currOptionsCount={currOptionsCount}
        optionsCount={optionsCount}
        setAvailableOptions={setAvailableOptions}
        setExpandedOption={setExpandedOption}
        setSearchTerm={setSearchTerm}
      >
        {({ option, isChecked }) => (
          <div className="flex items-center">
            <SingleCheckbox
              id={option.value}
              label={option.label}
              checked={isChecked(updatedValues)}
              onChange={handleCheckboxChange(option)}
            />
          </div>
        )}
      </SearchComponentNestedMenu>
    </div>
  );
};

const FilterCheckBox: FC<Props> = ({
  component,
  values,
  operation,
  isSearching,
  hasSearchFilters,
  searchCollection,
  formValues = [],
  onChange,
}) => {
  const { t } = useTranslation();
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [showSearch, setShowSearch] = useState<boolean>();
  const [availableOptions, setAvailableOptions] = useState<any[]>([]);
  const { searchInputCss } = inputFieldTheme();
  const { loading: isLoadingCount, getKeywordsCount } = useGetKeywordsCount();
  const [optionsCount, setOptionsCount] = useState<any>({});
  const formValuesCount = useMemo(
    () => getCount(component?.id, formValues),
    [component?.id, formValues],
  );

  const currOptionsCount =
    hasSearchFilters && operation !== LogicalOperators.OR
      ? formValuesCount
      : optionsCount;
  const optionValues = Object.keys(optionsCount);
  const options = availableOptions
    .filter((o) => o?.value && optionValues.includes(o.value))
    .map((o) => ({
      ...o,
      count: currOptionsCount[o.value] ?? 0,
    }));

  const handleClick = () => {
    const options = component?.properties?.options ?? [];

    setSearchTerm("");
    setShowSearch(false);
    setAvailableOptions(options);
  };

  const handleSearch = (searchTerm: string) => {
    const options = component?.properties?.options || [];
    let availableOptions: any[] = [...options];
    if (searchTerm) {
      const fuse = new Fuse(getFlattenedOptions(options), {
        includeScore: true,
        keys: ["label", "value"],
      });
      const results = fuse.search(searchTerm);
      availableOptions = results.map((res) => res.item);
    }

    setSearchTerm(searchTerm);
    setAvailableOptions(availableOptions);
  };

  const toggleSelectAll = () => {
    if (Boolean(values?.length === options.length)) {
      onChange([]);
    } else {
      onChange(options.map((o) => o.value));
    }
  };

  useEffect(() => {
    if (component?.id) {
      getKeywordsCount({
        collections: [
          {
            collection: searchCollection,
            keywordPath: `formValues.${component.id}`,
          },
        ],
        callback: (data) => {
          const keywordsCount = data?.[0]?.keywordCount ?? {};
          setOptionsCount(keywordsCount);
        },
      });
    }
  }, [component?.id, searchCollection, getKeywordsCount]);

  useEffect(() => {
    if (component?.properties?.options) {
      setAvailableOptions(component?.properties?.options);
    }

    return () => {
      setSearchTerm("");
      setAvailableOptions([]);
    };
  }, [component?.properties?.options]);

  if (isLoadingCount || isSearching) {
    return (
      <div className="mb-3 mt-1">
        <Skeleton height={5} />
      </div>
    );
  } else if (isEmpty(options) && !searchTerm) {
    return (
      <div className="mt-1 pb-2 text-sm text-gray-400">
        {t("No filters found")}
      </div>
    );
  }
  return (
    <div className="grid">
      <div className="flex items-center">
        {showSearch ? (
          <div className="w-full pb-3 pl-0">
            <MiniSearchField
              className={searchInputCss({
                size: "sm",
                class: "w-full border border-gray-300",
              })}
              placeholder="Liste durchsuchen ..."
              value={searchTerm}
              onChange={handleSearch}
              onClear={handleClick}
            />
          </div>
        ) : (
          <div className="flex h-12 items-center">
            <IconButton
              className="ml-[7px]"
              iconSize={1}
              Icon={MagnifyingGlassIcon}
              label="Open Search"
              onClick={() => setShowSearch(true)}
            />
          </div>
        )}
      </div>
      {options.length > 1 && (
        <div className="pb-0.5">
          <SingleCheckbox
            id="select_all"
            label="Select All"
            checked={Boolean(values?.length === options.length)}
            onChange={toggleSelectAll}
          />
        </div>
      )}
      <CheckBox
        className="mb-3"
        id={component.id}
        options={options}
        selectedOptions={values || []}
        onChange={onChange}
      />
    </div>
  );
};

export const SearchComponentsWrapper: FC<Props> = (props) => {
  const { component, values, onChange } = props;

  if (
    component?.validation?.type === ComponentValidationType.Number ||
    (component?.component === FormComponentTypes.Hidden &&
      (component.properties?.startAddonText ||
        component.properties?.endAddOnText))
  ) {
    const addOnText =
      component.properties?.endAddOnText ||
      component.properties?.startAddonText;

    return (
      <InputRangePicker
        id={component.id}
        fromAddonText={component.properties?.startAddOnText || addOnText}
        fromPlaceholder="from"
        value={values || {}}
        onChange={onChange}
        type="number"
        toAddonText={component.properties?.endAddOnText || addOnText}
        toPlaceholder="to"
      />
    );
  }
  switch (component?.component) {
    case FormComponentTypes.DatePicker:
    case FormComponentTypes.DatePickerRange:
      return (
        <DatePickerRange
          id={component.id}
          value={values || {}}
          onChange={onChange}
          size="sm"
          withPortal={true}
        />
      );
    case FormComponentTypes.AddressSearch:
      return (
        <div className="pb-3">
          <AddressSearch
            id={component.id}
            value={values}
            StartIcon={MapPinIcon}
            onChange={onChange}
            hideErrorMessage={true}
            hasMenuOverflow={true}
          />
        </div>
      );
    case FormComponentTypes.Input:
      return (
        <div className="pb-2">
          <TextField
            id="fullTextSearch"
            value={values || ""}
            onChange={onChange}
          />
        </div>
      );
    case FormComponentTypes.SearchAndSelectProjects:
      return (
        <div className="pb-3">
          <SearchAndSelectProjects
            id={component.id}
            projectIds={values}
            onChange={onChange}
            isMulti={true}
            isClearable={true}
            hideErrorMessage={true}
            hasMenuOverflow={true}
          />
        </div>
      );
    case FormComponentTypes.SearchAndSelect:
    case FormComponentTypes.LabelledContactInputs:
      if (!component?.properties?.contactType) {
        return null;
      }
      return (
        <div className="pb-3">
          <SidebarSearchContactByType
            id={component.id}
            contactId={values}
            contactType={component.properties.contactType}
            hideErrorMessage={true}
            hasMenuOverflow={true}
            onChange={onChange}
          />
        </div>
      );
    case FormComponentTypes.RegionalSelect:
      return (
        <div className="pb-3">
          <RegionalSelect
            id={component.id}
            regionId={values}
            onChange={onChange}
            shouldReturnId={true}
            hideErrorMessage={true}
            hasMenuOverflow={true}
          />
        </div>
      );
    default:
      if (Boolean(component?.properties?.isNested)) {
        return <NestedFilterCheckBox {...props} />;
      }
      return <FilterCheckBox {...props} />;
  }
};
